$("#news_widget .carousel-container").owlCarousel({
  singleItem: true,
  navigation: true,
  navigationText: ["", ""],
  rewindNav: false
});

$("#reviews-carousel .carousel-container").owlCarousel({
  singleItem: true,
  navigation: true,
  navigationText: ["", ""],
  rewindNav: false
});

$("#photo_widget .carousel-container").owlCarousel({
  itemsCustom: [[0, 3]],
  navigation: true,
  navigationText: ["", ""],
  rewindNav: false
});
